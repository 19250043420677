* {
  margin: 0;
  padding: 0;
}
header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(bg3.jpg);
  height: 100vh;
  background-position: center;
  background-size: cover;
}
.Soon {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-family: "Fira Sans", sans-serif;
}
h1 {
  font-size: 65px;
  letter-spacing: 15px;
}
hr {
  width: 50%;
  margin: 30px auto;
  border: 1.5px solid #fff;
}
p {
  font-size: 20px;
  margin-bottom: 15px;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 5px;
}
#launch {
  font-size: 1.2rem;
  letter-spacing: 10px;
  margin-top: 60px;
}

#launchh {
  font-size: 1rem;
  letter-spacing: 5px;
  margin-bottom: 0.5rem;
}

#tagline {
  font-size: 18px;
  margin-bottom: 60px;
  letter-spacing: 5px;
}
